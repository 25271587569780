/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
(function (Wistia) {
  return Wistia.integrations.register('google_analytics', {
    pushPercentWatchedEvent(video, percent) {
      const opts = {};
      if (video.lastPlayInfo().source === 'non-user-event') {
        opts.nonInteraction = true;
      }
      return this._pushEvent(`${Math.round(percent * 100)}% Watched`, video.name(), opts);
    },

    pushPlayEvent(video) {
      return this._pushEvent('Play', video.name());
    },

    pushConversionEvent(video, conversionType, conversionData) {
      return this._pushEvent('Conversion', video.name());
    },

    pushConversionMidrollLinkEvent(video, conversionData) {
      return this._pushEvent(`Clicked Link - ${conversionData.link}`, video.name());
    },

    pushConversionPostRollEvent(video, conversionData) {
      return this._pushEvent(`Clicked Link - ${conversionData.link}`, video.name());
    },

    pushConversionEventbriteEvent(video, conversionData) {
      return this._pushEvent(`Eventbrite Registration - ${conversionData.eventId}`, video.name());
    },

    _gaTrackers() {
      let tracker;
      let id;
      let result = [];
      const idToName = {}; // We only want one tracker per UA ID
      if (window.ga?.getAll) {
        for (tracker of Array.from(window.ga.getAll())) {
          id = tracker.get('trackingId');
          idToName[id] = tracker.get('name');
        }
      }
      if (window.gaTracker?.getAll) {
        for (tracker of Array.from(window.gaTracker.getAll())) {
          id = tracker.get('trackingId');
          idToName[id] = tracker.get('name');
        }
      }
      if (window.__gaTracker?.getAll) {
        for (tracker of Array.from(window.__gaTracker.getAll())) {
          id = tracker.get('trackingId');
          idToName[id] = tracker.get('name');
        }
      }
      return (result = (() => {
        const result1 = [];
        for (id in idToName) {
          const name = idToName[id];
          result1.push(name);
        }
        return result1;
      })());
    },

    // This is a private helper method, not a handler. But we can define it in
    // the handler object so they can all use it.
    _pushEvent(name, val, opts) {
      // We've encountered a case where `ga` is defined, but `GoogleAnalyticsObject`
      // is not. This uses `ga` as a fallback in this case.
      if (opts == null) {
        opts = {};
      }
      const ga = window[window.GoogleAnalyticsObject] || window.ga;
      if (ga != null) {
        return (() => {
          const result = [];
          for (let tracker of Array.from(this._gaTrackers())) {
            ga(`${tracker}.send`, 'event', 'Video', name, val, opts);
            result.push(
              Wistia.info(
                `Push to ${
                  window.GoogleAnalyticsObject || 'ga'
                }: ${tracker}.send, event, Video, ${name}, ${val}, nonInteraction:  ${
                  opts?.nonInteraction || false
                }`,
              ),
            );
          }
          return result;
        })();
      }
      if (window._gaq != null) {
        window._gaq.push(['_trackEvent', 'Video', name, val], opts);
        return Wistia.info(
          `Push to _gaq: _trackEvent, Video, ${name}, ${val}, nonInteraction: ${
            opts?.nonInteraction || false
          }`,
        );
      }
    },
  });
})(window.Wistia);
