/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { merge } from 'utilities/obj.js';

(function (Wistia) {
  return Wistia.integrations.register('hubspot', {
    onFind(video) {
      // Set the foreign data Wistia knows about right away,
      // when visitor key is ready set that.
      // and put the call to get the data from the HubSpot tracker on the hsq
      this._setVideoForeignData(video);

      Wistia.visitorKey.ready().then(() => {
        return this._setVisitorForeignData(video, Wistia.visitorKey.value());
      });

      // When the hsq is processed, the functions on the queue get called with a tracker param from HubSpot
      return window._hsq.push((tracker) => this._setHubSpotForeignData(video, tracker));
    },

    onInit() {
      // make sure _hsq (Hubspot Queue) is defined
      if (window._hsq == null) {
        window._hsq = [];
      }

      // Track whether we've sent the visitor_key to HS already
      let _haveSentVisitorKey = false;
      return Wistia.visitorKey.ready().then(() => {
        if (_haveSentVisitorKey) {
          return;
        }
        window._hsq.push(['identify', { wistia_visitor_key: Wistia.visitorKey.value() }]);
        return (_haveSentVisitorKey = true);
      });
    },

    _setVideoForeignData(video) {
      // The URL of the page (sans query string)
      const pageUrl = document.location.href.split('?')[0];

      // Trim whitespace from the page name
      let pageName = document.getElementsByTagName('title')[0]?.innerHTML || '';
      pageName = pageName.replace(/^[\n\s]+/, '').replace(/[\n\s]+$/, '');
      // XXX: Remove non-ASCII characters because they break base64 encoding and
      // cause our requests to the distillery to fail. There's a better fix, but
      // this isn't super important, so let's just do this for now.
      // eslint-disable-next-line no-control-regex
      pageName = pageName.replace(/[^\x00-\x7F]/g, '');

      return video.foreignData(
        merge({}, video.foreignData(), {
          page_url: pageUrl,
          page_name: pageName,
        }),
      );
    },

    _setVisitorForeignData(video, visitor_key) {
      return video.foreignData(merge({}, video.foreignData(), { wistia_visitor_key: visitor_key }));
    },

    _setHubSpotForeignData(video, tracker) {
      return video.foreignData(
        merge({}, video.foreignData(), {
          hubspot_hutk: tracker.utk.visitor,
          canonical_url: tracker.canonicalUrl,
          page_id: tracker.pageId,
          content_type: tracker.contentType,
        }),
      );
    },
  });
})(window.Wistia);
