/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import { merge } from 'utilities/obj.js';
import { getCookie } from 'utilities/cookie.js';

(function (Wistia) {
  const marketoIntegration = {
    onFind(video) {
      return this._setForeignData(video);
    },

    pushPercentWatchedEvent(video, percent) {
      // We use English 'percent' instead of a symbol % because of Marketo's parsing
      return this._pushEvent(video, `${Math.round(percent * 100)} Percent Watched`);
    },

    pushPlayEvent(video) {
      return this._pushEvent(video, 'Played');
    },

    pushConversionEvent(video, conversionType, conversionData) {
      return this._pushEvent(video, `Conversion - ${conversionData}`);
    },

    pushConversionMidrollLinkEvent(video, conversionData) {
      return this._pushEvent(video, `Clicked Link - ${conversionData.link}`);
    },

    pushConversionPostRollEvent(video, conversionData) {
      return this._pushEvent(video, `Clicked Link - ${conversionData.link}`);
    },

    pushConversionEventbriteEvent(video, conversionData) {
      return this._pushEvent(video, `Eventbrite Registration - ${conversionData.eventId}`);
    },

    _pushEvent(video, message) {
      if (!window.Munchkin) {
        return;
      }
      this._setForeignData(video);
      // Marketo treats a & the beginning of a query string parameter. We do not include any
      // & characters elsewhere, but a customer can include them in their video name
      // We avoid trouble by transforming an & to a simple and

      // NOTE: utf-8 quotes to get around html encoding
      const url = this._marketoUrlFormat(
        ` - Wistia Video - "${video.name().replace(/&amp;/g, 'and')}" - ${message}`,
      );
      return window.Munchkin.munchkinFunction('visitWebPage', { url });
    },

    _marketoUrlFormat(str) {
      // Marketo interprets a ? or ; as the start of the query string params and strips the rest of the string off
      // so the best we can do is replace it with a blank string.  Can't be escaped or URL encoded, still stripped
      const partialFixedStr = str.replace(/[?;]/g, ' ');
      // Marketo does some uri encoding on query params; if there is a : in a video title, we want to replace it with
      // something that won't get weird on their side.
      return partialFixedStr.replace(/[:]/g, ' - ');
    },

    _setForeignData(video, tracker) {
      if (tracker == null) {
        tracker = '';
      }
      if (!this._cookie) {
        this._setCookie();
      }
      return video.foreignData(merge({}, video.foreignData(), { marketoCookie: this._cookie }));
    },

    _setCookie() {
      return (this._cookie = getCookie('_mkto_trk'));
    },
  };

  // This module supports both marketo and marketo_v2 vendor.
  // marketo is our deprecated vendor and marketo_v2 is the current vendor.
  Wistia.integrations.register('marketo_v2', marketoIntegration);
  return Wistia.integrations.register('marketo', marketoIntegration);
})(window.Wistia);
