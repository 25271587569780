import { camelCase } from '../../../utilities/core.js';
import { countMetric } from '../../../utilities/simpleMetrics.js';
import { onFindApiHandleRunOnce, ALL_API_HANDLES_KEY } from '../../../utilities/onFindApiHandle.ts';

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

(function (Wistia) {
  if (Wistia.integrations) {
    return;
  }
  Wistia.integrations = {};
  Wistia.integrations._all = {};

  // This is the entry point for defining an integration. We're basically just
  // giving it an object with well-defined handlers that perform actions on
  // specific kinds of events.
  //
  // We don't want the implementations to be checking whether they're gated all
  // over the place, so let's handle that with a single flag.
  Wistia.integrations.register = function (integrationType, handlers) {
    // gateHandler returns a function that is guaranteed to execute if and only
    // if:
    //
    // a) the video has data
    // b) the integration is turned on for the video
    //
    // The purpose is to automatically wrap handlers defined by the
    // integrations themselves.
    const gateHandler = function (method) {
      if (!handlers[method]) {
        return null;
      }

      const fn = (video, ...args) =>
        video.hasData(() => {
          if (video.params?.[camelCase(integrationType)] === false) {
            return;
          }

          if (video._mediaData?.integrations?.[integrationType]) {
            return handlers[method](video, ...Array.from(args));
          }
        });

      return fn;
    };

    const gateInitHandler = function (method) {
      if (!handlers[method]) {
        return null;
      }
      handlers.initialized = false;

      const initFn = (video, ...args) =>
        video.hasData(() => {
          if (video.params?.[camelCase(integrationType)] === false) {
            return;
          }

          if (window.disableWistiaIntegrationTracking === true) {
            return;
          }

          if (!handlers.initialized && video._mediaData?.integrations?.[integrationType]) {
            handlers[method](video, ...Array.from(args));
            return (handlers.initialized = true);
          }
        });

      return initFn;
    };

    const gatedHandlers = {
      pushPercentWatchedEvent: gateHandler('pushPercentWatchedEvent'),
      pushPlayEvent: gateHandler('pushPlayEvent'),
      pushEndedEvent: gateHandler('pushEndedEvent'),
      pushConversionEvent: gateHandler('pushConversionEvent'),
      pushConversionMidrollLinkEvent: gateHandler('pushConversionMidrollLinkEvent'),
      pushConversionPostRollEvent: gateHandler('pushConversionPostRollEvent'),
      pushConversionEventbriteEvent: gateHandler('pushConversionEventbriteEvent'),
      onFind: gateHandler('onFind'),
      onInit: gateInitHandler('onInit'),
    };
    return (Wistia.integrations._all[integrationType] = gatedHandlers);
  };

  // Run a function for each integration. String/arg sugar since that's the
  // shortest form and usable all over the place.
  Wistia.integrations.each = (fn, ...args) =>
    (() => {
      const result = [];
      for (let integrationName in Wistia.integrations._all) {
        let handle = Wistia.integrations._all[integrationName];
        if (typeof fn === 'string') {
          result.push(handle[fn]?.(...Array.from(args || [])));
        } else {
          result.push(fn(handle, integrationName));
        }
      }
      return result;
    })();

  Wistia._initializers.initIntegrations = function () {
    if (Wistia.integrations._setup) {
      return;
    }
    return Wistia.integrations.setup();
  };

  Wistia._destructors.destroyIntegrations = function () {
    window.wistiaEmbeds?.each((video) => video._destroyIntegrations?.());
    this._all = {};
    return (Wistia.integrations._setup = false);
  };

  return (Wistia.integrations.setup = function () {
    // We initialize this every time E-v1 is loaded to make sure it happens
    // after wistiaEmbeds is defined. So we protect from double init here.
    if (window._inWistiaIframe) {
      return;
    }
    Wistia.integrations._setup = true;

    return onFindApiHandleRunOnce({
      matcher: ALL_API_HANDLES_KEY,
      shouldOnlyRunWhenVisible: true,
      functionToRun: (video) => {
        countMetric('player/integrations-setup', 1, {
          href: window.location.href,
        });

        const percentWatchedThresholdCallback = (threshold) =>
          Wistia.integrations.each('pushPercentWatchedEvent', video, threshold);

        // Execute conversion handler for all integrations. This will happen as
        // often as the "conversion" event fires on the video.
        const conversionCallback = function () {
          return Wistia.integrations.each('pushConversionEvent', video, ...arguments);
        };

        const conversionMidrollLinkCallback = (data) =>
          Wistia.integrations.each('pushConversionMidrollLinkEvent', video, data);

        const conversionPostRollCallback = (data) =>
          Wistia.integrations.each('pushConversionPostRollEvent', video, data);

        const conversionEventbriteCallback = (data) =>
          Wistia.integrations.each('pushConversionEventbriteEvent', video, data);

        // Execute played handler for all integrations. Only do this once per
        // video.  That is, don't push if they pause then play again.
        const playCallback = function () {
          Wistia.integrations.each('pushPlayEvent', video);
          return video.unbind;
        };

        // Once a Video has ended, we want to reinitialize the play callback
        // to send a play event in the event the page hasn't been updated.
        const endedCallback = function () {
          video.bind('play', playCallback);
          return Wistia.integrations.each('pushEndedEvent', video);
        };

        // In here is where we actually attach the above functions to the video.
        const bindCallbacks = () =>
          video.hasData(() => {
            video.bind('percentWatchedThreshold', percentWatchedThresholdCallback);
            // Restricted to email conversions, i.e. Turnstile
            video.bind('conversion', conversionCallback);

            video.bind('conversion-link', conversionMidrollLinkCallback);
            video.bind('conversion-postRoll', conversionPostRollCallback);
            video.bind('conversion-eventbrite', conversionEventbriteCallback);

            video.bind('play', playCallback);
            return video.bind('end', endedCallback);
          });

        // We also need to unbind the functions sometimes--that's why we were
        // storing percentWatchedCallbacks in an array above.
        const unbindCallbacks = function () {
          video.unbind('play', playCallback);
          video.unbind('conversion', conversionCallback);
          video.unbind('percentWatchedThreshold', percentWatchedThresholdCallback);
          return video.unbind('end', endedCallback);
        };

        // Define this publicly so we can init/destroy integration functionality
        // globally.
        video._initIntegrations = () => bindCallbacks();
        video._destroyIntegrations = () => unbindCallbacks();

        // When the video is replaced, we want to capture that as if it's a
        // totally new embed.
        video.bind('beforereplace', unbindCallbacks);
        video.bind('afterreplace', bindCallbacks);

        // Setup immediately--we just found the video after all!
        bindCallbacks();

        // Call any initialization code for each integration on this video.
        Wistia.integrations.each('onInit', video);
        Wistia.integrations.each('onFind', video);

        // Send a 'played' event if the video is already playing when we find it.
        if (video.state === 'playing') {
          return playCallback();
        }
      },
    });
  });
})(window.Wistia);
